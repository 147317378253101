import { createRouter , createWebHistory } from 'vue-router'
import { store } from '../store/index'


const lazyLoad = (view) => { return () => import(`../views/${view}.vue`) }


const routes = [
  {
    path: '/',
    name: 'summary',
    component: lazyLoad('Dashboard/Main/Summary'),
    meta: { requiresAuth: true }
  },
  {
    path: '/t-and-c',
    name: 't&c',
    component: lazyLoad('Dashboard/Utility/TermsAndConditions'),
  },
  {
    path: '/exam/:examType/:examLanguage/:examId',
    name: 'exam',
    component: lazyLoad('Dashboard/Main/Exam'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts/:userRole/:userLanguage',
    name: 'sign-contract',
    component: lazyLoad('Dashboard/Main/SignContract'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: lazyLoad('Auth/Login'),
    meta: { requiresGuest: true }
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: lazyLoad('Auth/Register'),
    meta: { requiresGuest: true }
  },
  {
    path: '/reset-password/:emailBase64/:emailHash',
    name: 'reset-password',
    component: lazyLoad('Auth/NewPassword') 
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: lazyLoad('Auth/RecoverPassword')
  },
  {
    path: '/recover-password/email',
    name: 'recover-password-email',
    component: lazyLoad('Auth/PasswordResetLinkSent')
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: lazyLoad('Auth/ThankYou')
  },
  {
    path: '/verify-email/:userId/:emailHash',
    name: 'verify-email',
    component: lazyLoad('Auth/EmailValidation')
  },
  {
    path: '/email-verification-required',
    name: 'email-verification-required',
    component: lazyLoad('Auth/EmailVerificationRequired')
  },
  {
    path: '/register-data',
    name: 'register-data',
    component: lazyLoad('Auth/RegisterData'),
    meta: { requiresAuth: true }
  },
  {
    path: '/cash-flows',
    name: 'cash-flows',
    component: lazyLoad('Dashboard/Main/CashFlows'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user-stats',
    name: 'user-stats',
    component: lazyLoad('Dashboard/Main/UserStats'),
    meta: { requiresAuth: true }
  },
  {
    path: '/news/author-searching',
    name: 'news',
    component: lazyLoad('Dashboard/Texts/AuthorSearching'),
    meta: { requiresAuth: true }
  },
  {
    path: '/title-assignment/:titleId',
    name: 'title-assignemnt',
    component: lazyLoad('Dashboard/Texts/TitleAssignment'),
    meta: { requiresAuth: true }
  },
  {
    path: '/title-detail/:titleId',
    name: 'title-detail',
    component: lazyLoad('Dashboard/Texts/TitleDetail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/news/waiting-for-you',
    name: 'waiting-for-you',
    component: lazyLoad('Dashboard/Texts/WaitingForYou'),
    meta: { requiresAuth: true }
  },
  {
    path: '/news/writing',
    name: 'writing',
    component: lazyLoad('Dashboard/Texts/Writing'),
    meta: { requiresAuth: true }
  },
  {
    path: '/news/drafts',
    name: 'drafts',
    component: lazyLoad('Dashboard/Texts/Drafts'),
    meta: { requiresAuth: true }
  },
  {
    path: '/news/published',
    name: 'published',
    component: lazyLoad('Dashboard/Texts/Published'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreader-news/proofreader-searching',
    name: 'proofreader-news',
    component: lazyLoad('Dashboard/Texts/ProofreaderSearching'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreader-news/waiting-for-you',
    name: 'proofreader-waiting-for-you',
    component: lazyLoad('Dashboard/Texts/ProofreaderWaitingForYou'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreader-news/writing',
    name: 'proofreader-writing',
    component: lazyLoad('Dashboard/Texts/ProofreaderWriting'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreader-news/drafts',
    name: 'proofreader-drafts',
    component: lazyLoad('Dashboard/Texts/ProofreaderDrafts'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreader-news/published',
    name: 'proofreader-published',
    component: lazyLoad('Dashboard/Texts/ProofreaderPublished'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreaders',
    name: 'proofreaders',
    component: lazyLoad('Dashboard/Texts/Proofreaders'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contents/pending',
    name: 'pending-contents',
    component: lazyLoad('Dashboard/Texts/PendingContents'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contents/to-be-delivered',
    name: 'to-be-delivered-contents',
    component: lazyLoad('Dashboard/Texts/ToBeDeliveredContents'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contents/delivered',
    name: 'delivered-contents',
    component: lazyLoad('Dashboard/Texts/DeliveredContents'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contents/accepted',
    name: 'accepted-contents',
    component: lazyLoad('Dashboard/Texts/AcceptedContents'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contents/rejected',
    name: 'rejected-contents',
    component: lazyLoad('Dashboard/Texts/RejectedContents'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:userRole/contents/:contentId',
    name: 'content-details',
    component: lazyLoad('Dashboard/Texts/ContentDetails'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:userRole/translations/:contentId',
    name: 'translation-details',
    component: lazyLoad('Dashboard/Texts/ContentDetails'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:userRole/proofreadings/:contentId',
    name: 'proofreading-details',
    component: lazyLoad('Dashboard/Texts/ContentDetails'),
    meta: { requiresAuth: true }
  },
  {
    path: '/translations/pending',
    name: 'pending-translations',
    component: lazyLoad('Dashboard/Texts/PendingTranslations'),
    meta: { requiresAuth: true }
  },
  {
    path: '/translations/to-be-delivered',
    name: 'to-be-delivered-translations',
    component: lazyLoad('Dashboard/Texts/ToBeDeliveredTranslations'),
    meta: { requiresAuth: true }
  },
  {
    path: '/translations/delivered',
    name: 'delivered-translations',
    component: lazyLoad('Dashboard/Texts/DeliveredTranslations'),
    meta: { requiresAuth: true }
  },
  {
    path: '/translations/accepted',
    name: 'accepted-translations',
    component: lazyLoad('Dashboard/Texts/AcceptedTranslations'),
    meta: { requiresAuth: true }
  },
  {
    path: '/translations/rejected',
    name: 'rejected-translations',
    component: lazyLoad('Dashboard/Texts/RejectedTranslations'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreadings/pending',
    name: 'pending-proofreadings',
    component: lazyLoad('Dashboard/Texts/PendingProofreadings'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreadings/to-be-delivered',
    name: 'to-be-delivered-proofreadings',
    component: lazyLoad('Dashboard/Texts/ToBeDeliveredProofreadings'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreadings/delivered',
    name: 'delivered-proofreadings',
    component: lazyLoad('Dashboard/Texts/DeliveredProofreadings'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreadings/accepted',
    name: 'accepted-proofreadings',
    component: lazyLoad('Dashboard/Texts/AcceptedProofreadings'),
    meta: { requiresAuth: true }
  },
  {
    path: '/proofreadings/rejected',
    name: 'rejected-proofreadings',
    component: lazyLoad('Dashboard/Texts/RejectedProofreadings'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customers',
    name: 'customers',
    component: lazyLoad('Dashboard/Texts/Customers'),
    meta: { requiresAuth: true, requiresSuperAdmin:true   }
  },
  {
    path: '/customers/:customerId',
    name: 'customer',
    component: lazyLoad('Dashboard/Texts/Customer'),
    meta: { requiresAuth: true, requiresSuperAdmin:true   }
  },
  {
    path: '/websites-categories',
    name: 'websites-categories',
    component: lazyLoad('Dashboard/Texts/WebsitesCategories'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/websites-tags',
    name: 'websites-tags',
    component: lazyLoad('Dashboard/Texts/WebsitesTags'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/exams',
    name: 'exams',
    component: lazyLoad('Dashboard/Users/Exams'),
    meta: { requiresAuth: true, requiresSuperAdmin:true  }
  },
  {
    path: '/exams/:examId',
    name: 'admin-exam',
    component: lazyLoad('Dashboard/Users/Exam'),
    meta: { requiresAuth: true, requiresSuperAdmin:true  }
  },
  {
    path: '/users',
    name: 'users',
    component: lazyLoad('Dashboard/Users/Users'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/users/:userId',
    name: 'user',
    component: lazyLoad('Dashboard/Users/User'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/groups',
    name: 'groups',
    component: lazyLoad('Dashboard/Users/Groups'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/groups/:groupId',
    name: 'group',
    component: lazyLoad('Dashboard/Users/Group'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/reports',
    name: 'reports',
    component: lazyLoad('Dashboard/Users/Reports'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fees',
    name: 'fees',
    component: lazyLoad('Dashboard/Accounting/Fees'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payments/paid',
    name: 'paid-payments',
    component: lazyLoad('Dashboard/Accounting/PaidPayments'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payments/unpaid',
    name: 'unpaid-payments',
    component: lazyLoad('Dashboard/Accounting/UnpaidPayments'),
    meta: { requiresAuth: true }
  },
  {
    path: '/process-payments',
    name: 'process-payments',
    component: lazyLoad('Dashboard/Accounting/ProcessPayments'),
    meta: { requiresAuth: true }
  },
  {
    path: '/saas-articles-cost',
    name: 'saas-articles-cost',
    component: lazyLoad('Dashboard/Accounting/SaasArticlesCost'),
    meta: { requiresAuth: true }
  },
  {
    path: '/titles-articles-cost',
    name: 'titles-articles-cost',
    component: lazyLoad('Dashboard/Accounting/TitlesArticlesCost'),
    meta: { requiresAuth: true }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: lazyLoad('Dashboard/Accounting/Invoices'),
    meta: { requiresAuth: true }
  },
  {
    path: '/skipped-invoices',
    name: 'skipped-invoices',
    component: lazyLoad('Dashboard/Accounting/SkippedInvoices'),
    meta: { requiresAuth: true }
  },
  {
    path: '/invoices/:dateTo',
    name: 'invoices-report',
    component: lazyLoad('Dashboard/Accounting/InvoicesReport'),
    meta: { requiresAuth: true }
  },
  {
    path: '/invoice-details/:invoiceId',
    name: 'invoice-details',
    component: lazyLoad('Dashboard/Accounting/InvoiceDetails'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: lazyLoad('Dashboard/HelpDesk/Tickets'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tickets/:ticketId',
    name: 'ticket',
    component: lazyLoad('Dashboard/HelpDesk/Ticket'),
    meta: { requiresAuth: true }
  },
  {
    path: '/stats',
    name: 'stats',
    component: lazyLoad('Dashboard/HelpDesk/Stats'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/general',
  //   name: 'general',
  //   component: lazyLoad('Dashboard/Utility/General'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/email-campaigns',
    name: 'email-campaigns',
    component: lazyLoad('Dashboard/Utility/EmailCampaigns'),
    meta: { requiresAuth: true }
  },
  {
    path: '/admin-terms-and-conditions',
    name: 'admin-terms-and-conditions',
    component: lazyLoad('Dashboard/Utility/AdminTermsAndConditions'),
    meta: { requiresAuth: true }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: lazyLoad('Dashboard/Utility/TermsAndConditions'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/user-terms-and-conditions',
    name: 'user-terms-and-conditions',
    component: lazyLoad('Dashboard/Utility/UserTermsAndConditions'),
    meta: { requiresAuth: true }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: lazyLoad('Dashboard/Utility/PrivacyPolicy'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users-faq',
    name: 'users-faq',
    component: lazyLoad('Dashboard/Utility/UsersFAQ'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/users-faq/:faqId',
    name: 'users-single-faq',
    component: lazyLoad('Dashboard/Utility/UsersSingleFAQ'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/faq',
    name: 'faq',
    component: lazyLoad('Dashboard/Utility/FAQ'),
    meta: { requiresAuth: true }
  },
  {
    path: '/websites',
    name: 'websites',
    component: lazyLoad('Dashboard/Settings/Websites'),
    meta: { requiresAuth: true, requiresSuperAdmin:true  }
  },
  {
    path: '/websites/:websiteId',
    name: 'website',
    component: lazyLoad('Dashboard/Settings/Website'),
    meta: { requiresAuth: true, requiresSuperAdmin:true  }
  },
  {
    path: '/config',
    name: 'config',
    component: lazyLoad('Dashboard/Settings/Config'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/assignment-rules',
    name: 'assignment-rules',
    component: lazyLoad('Dashboard/Settings/AssignmentRules'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/rules/:ruleId',
    name: 'rule',
    component: lazyLoad('Dashboard/Settings/Rule'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/categories',
    name: 'categories',
    component: lazyLoad('Dashboard/Settings/Categories'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/categories/:categoryId',
    name: 'category',
    component: lazyLoad('Dashboard/Settings/Category'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: lazyLoad('Dashboard/Settings/Contracts'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/contracts/:roleId',
    name: 'contract',
    component: lazyLoad('Dashboard/Settings/Contract'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/professions',
    name: 'professions',
    component: lazyLoad('Dashboard/Settings/Professions'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/professions/:professionId',
    name: 'profession',
    component: lazyLoad('Dashboard/Settings/Profession'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/contract-types',
    name: 'contract-types',
    component: lazyLoad('Dashboard/Settings/ContractTypes'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/contract-types/:contractTypeId',
    name: 'contract-type',
    component: lazyLoad('Dashboard/Settings/ContractType'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/education-levels',
    name: 'education-levels',
    component: lazyLoad('Dashboard/Settings/EducationLevels'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/education-levels/:educationLevelId',
    name: 'education-level',
    component: lazyLoad('Dashboard/Settings/EducationLevel'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/editor-tests',
    name: 'editor-tests',
    component: lazyLoad('Dashboard/Settings/EditorTests'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/editor-tests/:testId',
    name: 'editor-test',
    component: lazyLoad('Dashboard/Settings/EditorTest'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/translator-tests',
    name: 'translator-tests',
    component: lazyLoad('Dashboard/Settings/TranslatorTests'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/translator-tests/:testId',
    name: 'translator-test',
    component: lazyLoad('Dashboard/Settings/TranslatorTest'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/proofreader-tests',
    name: 'proofreader-tests',
    component: lazyLoad('Dashboard/Settings/ProofreaderTests'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/proofreader-tests/:testId',
    name: 'proofreader-test',
    component: lazyLoad('Dashboard/Settings/ProofreaderTest'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/user-report',
    name: 'user-report',
    component: lazyLoad('Profile/UserReport'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user-credentials',
    name: 'user-credentials',
    component: lazyLoad('Profile/UserCredentials'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user-preferences',
    name: 'user-preferences',
    component: lazyLoad('Profile/UserPreferences'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user-notifications',
    name: 'user-notifications',
    component: lazyLoad('Profile/UserNotifications'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user-settings',
    name: 'user-settings',
    component: lazyLoad('Profile/UserSettings'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: lazyLoad('Profile/Profile'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profile/experience/:roleId',
    name: 'profile-experience',
    component: lazyLoad('Profile/ProfileExperience'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customer-assignment/:customerEmail',
    name: 'customer-assignment',
    component: lazyLoad('Dashboard/Texts/CustomerAssignment'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/guideline-assignment/:guidelineId',
    name: 'guideline-assignment',
    component: lazyLoad('Dashboard/Texts/GuidelineAssignment'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/order-assignment/:orderId',
    name: 'order-assignment',
    component: lazyLoad('Dashboard/Texts/OrderAssignment'),
    meta: { requiresAuth: true, requiresSuperAdmin:true }
  },
  {
    path: '/banned',
    name: 'banned',
    component: lazyLoad('System/Banned'),
  },
  { 
    path: "/:pathMatch(.*)*",
    name:'not-found',
    component: lazyLoad('System/PathNotFound')
  }
  
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(){
    return { top:0 }
  },
  routes
});


const parseJwt = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth && !record.meta.requiresSuperAdmin)) {
    if (localStorage.getItem('token') && localStorage.getItem('token') != '' && (localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token') !== undefined) && (localStorage.getItem('token') !== 'null' && localStorage.getItem('token') !== null)) {
      console.log('c')
      const jwtPayload = parseJwt(localStorage.getItem('token'));
      if (jwtPayload.exp*1000 < Date.now() || !jwtPayload.iss.includes(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}`)){
        localStorage.removeItem('token')
        next("/login");
      }else{
        if(to.name==='register-data'){
          next();
        }else{
          store.dispatch('userModule/getUserInfo', { withFiscal:false }).then(() => {
            next();
          })
        }
      }
    } else {
      next("/login");
    }
  }else if(to.matched.some((record) => record.meta.requiresGuest)){
    if (localStorage.getItem('token') && localStorage.getItem('token') != '' && (localStorage.getItem('token') !== 'undefined' || localStorage.getItem('token') !== undefined) && (localStorage.getItem('token') !== 'null' && localStorage.getItem('token') !== null)) {
      const jwtPayload = parseJwt(localStorage.getItem('token'));
      if (jwtPayload.exp*1000 < Date.now() || !jwtPayload.iss.includes(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/auth`)){
        next();
      }else{
          next('/');
      }
    }else{
      next();
    }
  }else if(to.matched.some((record) => record.meta.requiresAuth && record.meta.requiresSuperAdmin)){
    if (localStorage.getItem('token') && localStorage.getItem('token') != '' && (localStorage.getItem('token') !== 'undefined' || localStorage.getItem('token') !== undefined) && (localStorage.getItem('token') !== 'null' && localStorage.getItem('token') !== null)) {
      const jwtPayload = parseJwt(localStorage.getItem('token'));
      if (jwtPayload.exp*1000 < Date.now() || !jwtPayload.iss.includes(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}`)){
        localStorage.removeItem('token')
        next("/login");
      }else{
        // if(Object.keys(store.state.userModule.userData).length > 0 && store.state.userModule.userData.constructor === Object){
        //   if(store.state.userModule.isSuperAdmin){
        //     next();
        //   }else{
        //     next('/')
        //   }
        // }else{
          store.dispatch('userModule/getUserInfo', { withFiscal:false }).then(() => {
            if(store.state.userModule.isSuperAdmin || store.state.userModule.isAdmin){
              next();
            }else{
              next('/')
            }
        })
      // }
      }
    } else {
      next("/login");
    }
  }else{
    next();

  }
  
});



export default router
